

































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import dayjs from "dayjs";
import { SearchHistoryEntry } from "@/assets/classes/SearchHistory.ts";
import { Vehicle } from "@/assets/classes/Vehicle.ts";
import { NICParams } from "@/types";

const RH = new RequestHandler();

@Component
export default class NCICHits extends Vue {
  private AllNCICHits: SearchHistoryEntry[] = [];
  private pastSearchesSearchText = "";
  private page = 1;
  private itemsPerPage = 5;
  private tableHeaders = [
    { text: "VIN", value: "vin" },
    { text: "Plate Number", value: "plateNo" },
    { text: "Search Date", value: "searchDate" },
    { text: "Type", value: "statusCode" },
    { text: "Status", value: "status" },
    { text: "Theft Information", value: "" },
    { text: "VI", valu: "" }
  ];
  private searchID = -1;
  private theftDialog = false;
  private vehicleInfoDialog = false;
  private waitingOnLetter = true;
  private letterBin64 = "";
  private waitingOnVIDoc = false;
  private viDoc = "";

  private theftInfoForm = {
    valid: false,
    datePicker: false,
    nicID: "",
    agency: "",
    dateOfTheft: "",
    confirmPrint: false,
    confirmPrintErrorMsg: "",
    editable: false
  };

  private vehicleInfoForm = {
    valid: false,
    confirmPrint: false,
    confirmPrintErrorMsg: "",
    editable: false
  };

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    date: (value: string) => {
      let validDate = false;
      if (value.length === 10) {
        validDate = true;
        const tempValue = value.replaceAll("/", "");
        for (let i = 0; i < 8; i++) {
          if (isNaN(parseInt(tempValue.charAt(i)))) {
            validDate = false;
            break;
          }
        }
      }
      return validDate || "Please enter a date in the form of mmddyyyyy";
    }
  };

  private statusFilter = "All";
  private statusBoxItems = ["All", "Complete", "Incomplete"];

  get filteredNCICHits() {
    return this.AllNCICHits.filter(item => {
      switch (this.statusFilter) {
        case "Complete":
          return item.nic.complete;
        case "Incomplete":
          return !item.nic.complete;
        default:
          return item;
      }
    });
  }

  get theftInfoFormRef(): Vue & { validate: () => boolean } {
    return this.$refs.theftInfoFormRef as Vue & { validate: () => boolean };
  }

  get vehicleInfoFormRef(): Vue & { validate: () => boolean } {
    return this.$refs.vehicleInfoFormRef as Vue & { validate: () => boolean };
  }

  public async openTheftDialog(item: SearchHistoryEntry) {
    this.waitingOnLetter = true;
    this.theftDialog = true;
    this.searchID = item.searchID;
    this.theftInfoForm.editable = !item.nic.complete;
    if (!this.theftInfoForm.editable) {
      this.theftInfoForm.nicID = item.nic.id;
      this.theftInfoForm.agency = item.nic.lawEnforcementAgency;
      this.theftInfoForm.dateOfTheft = dayjs(item.nic.theftDate).format(
        "MMDDYYYY"
      );
    }

    if (item.nic.complete) {
      try {
        this.letterBin64 = await RH.getStolenVehicleLetter(this.searchID);
        if (this.letterBin64 !== "") {
          this.letterBin64 = `data:application/pdf;base64,${this.letterBin64}`;
        }
      } catch (e) {
        this.letterBin64 = "";
      }
    }
    this.waitingOnLetter = false;
  }

  public async openVehicleInfoDialog(item: SearchHistoryEntry) {
    this.vehicleInfoDialog = true;
    this.searchID = item.searchID;
    this.vehicleInfoForm.editable = !item.nic.complete;
  }

  private clearTheftInfoForm() {
    this.theftInfoForm.nicID = "";
    this.theftInfoForm.agency = "";
    this.theftInfoForm.dateOfTheft = "";
    this.theftInfoForm.confirmPrint = false;
    this.theftInfoForm.confirmPrintErrorMsg = "";
    this.theftInfoForm.editable = false;
    this.letterBin64 = "";
  }

  private clearVehicleInfoForm() {
    this.vehicleInfoForm.confirmPrint = false;
    this.vehicleInfoForm.confirmPrintErrorMsg = "";
    this.vehicleInfoForm.editable = false;
    this.viDoc = "";
  }

  private confirmPrintCheckbox() {
    if (this.theftInfoForm.confirmPrint) {
      this.theftInfoForm.confirmPrintErrorMsg = "";
    } else {
      this.theftInfoForm.confirmPrintErrorMsg = "This field is required.";
    }
  }

  private async submitInitialInfo(theftInfoForm: any) {
    if (!theftInfoForm.validate()) {
      return;
    }
    const params = {
      searchID: this.searchID,
      nicID: this.theftInfoForm.nicID,
      lawEnforcementAgency: this.theftInfoForm.agency,
      theftDate: dayjs(this.theftInfoForm.dateOfTheft).toISOString(),
      nicComplete: null
    } as NICParams;
    try {
      await RH.completeNicReport(params);
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Failed to send NIC data. Please try again. Is the Date of Theft in the form of mmddyyyy?"
      );
      return;
    }
    try {
      this.letterBin64 = await RH.getStolenVehicleLetter(this.searchID);
      if (this.letterBin64 !== "") {
        this.letterBin64 = `data:application/pdf;base64,${this.letterBin64}`;
      }
    } catch (e) {
      this.letterBin64 = "";
      this.$root.$emit(
        "snackbar-message",
        "Failed to generate letter. Please try again"
      );
      return;
    }
  }

  private async submitNICInfo(theftInfoForm: any) {
    if (!this.theftInfoForm.confirmPrint) {
      this.theftInfoForm.confirmPrintErrorMsg = "This field is required.";
    } else {
      let params = {} as NICParams;
      if (!theftInfoForm.validate()) {
        return;
      }
      params = {
        searchID: this.searchID,
        nicID: this.theftInfoForm.nicID,
        lawEnforcementAgency: this.theftInfoForm.agency,
        theftDate: dayjs(this.theftInfoForm.dateOfTheft).toISOString(),
        nicComplete: new Date().toISOString()
      };
      try {
        await RH.completeNicReport(params);
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Failed to send NIC data. Please try again. Is the Date of Theft in the form of ddmmyyyy?"
        );
        return;
      }
      this.searchID = -1;
      this.clearTheftInfoForm();
      this.theftDialog = false;
      this.getNCICHits();
      this.$root.$emit("snackbar-message", "Submission successful.");
    }
  }

  private async generateVIDocument(docType: string) {
    try {
      if (docType === "letter") {
        this.viDoc = await RH.getOwnershipLetter(this.searchID);
        if (this.viDoc !== "") {
          this.viDoc = `data:application/pdf;base64,${this.viDoc}`;
        }
      } else {
        this.viDoc = await RH.getActiveVehicleForm(this.searchID);
        if (this.viDoc !== "") {
          this.viDoc = `data:application/pdf;base64,${this.viDoc}`;
        }
      }
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Failed to generate VI Document. Please try again."
      );
      return;
    }
  }

  private async completeVIDialog(vehicleInfoForm: any) {
    if (!this.vehicleInfoForm.confirmPrint) {
      this.vehicleInfoForm.confirmPrintErrorMsg = "This field is required.";
    } else {
      let params = {} as NICParams;
      if (!vehicleInfoForm.validate()) {
        return;
      }
      params = {
        searchID: this.searchID,
        nicID: "",
        lawEnforcementAgency: "",
        theftDate: null,
        nicComplete: new Date().toISOString()
      };
      try {
        await RH.completeNicReport(params);
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Failed to send NIC data. Please try again."
        );
        return;
      }
      this.searchID = -1;
      this.clearVehicleInfoForm();
      this.vehicleInfoDialog = false;
      this.getNCICHits();
      this.$root.$emit("snackbar-message", "Submission successful.");
    }
  }

  private async getNCICHits() {
    this.AllNCICHits = [];
    let response: any;
    try {
      response = await RH.getNCICHits();
    } catch (e) {
      console.error(e);
      return;
    }
    response.forEach((historyEntry: { [key: string]: any } | undefined) => {
      this.AllNCICHits.push(new SearchHistoryEntry(historyEntry));
    });
  }

  async created() {
    await this.getNCICHits();
  }
}
